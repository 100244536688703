:root {
	--droppaddingtopbottom: .5em;
	--droppaddingleftright: 1em;
	--borderthickness: 0px;
	--bordercolor: currentColor;
}

#header .header-inner .header-nav-folder-content {
	border: var(--borderthickness) solid var(--bordercolor);
	box-sizing: border-box;
	padding: var(--droppaddingtopbottom) var(--droppaddingleftright);
	padding-right: 0;
}

#header .header-inner .header-nav-folder-content .header-nav-sub-folder {
	position: absolute;
	top: calc(var(--droppaddingtopbottom) * -1 - var(--borderthickness));
	left: 100%;
	padding: var(--droppaddingtopbottom) var(--droppaddingleftright);
	box-sizing: border-box;
	opacity: 0;
	pointer-events: none;
}

#header .header-inner .header-nav-folder-content .header-nav-folder-item {
	box-sizing: border-box;
	padding-right: var(--droppaddingleftright);
}

#header .header-inner .header-nav-folder-content .has-dropdown {
	position: relative;
}

#header .header-inner .header-nav-folder-content .has-dropdown:hover .header-nav-sub-folder,
#header .header-inner .header-nav-folder-content .has-dropdown:focus-within > a + .header-nav-sub-folder {
	opacity: 1;
	pointer-events: auto;
}

#header .header-inner.header-layout-nav-right .header-nav-folder-content:not(.header-nav-sub-folder) {
	padding-left: 0;
}

#header .header-inner.header-layout-nav-right .header-nav-sub-folder {
	left: auto;
	right: 100%;
	padding-right: 0;
}

[data-folder="root"].header-menu-nav-folder--active:has(~[data-sub-folder].header-menu-nav-folder--active),
[data-folder]:not([data-folder="root"]).header-menu-nav-folder--active:has(~[data-sub-folder].header-menu-nav-folder--active) {
	transform: translateX(-100%);
}

[data-folder]:not([data-folder="root"]).header-menu-nav-folder--active.header-menu-nav-folder--open {
	transform: translateX(0) !important;
}
